<template>
  <div>
    <div class="h-handle-bg">
      <div class="h-handle-button">
        <p class="h-return">
          <el-button type="text" size="small" @click="config.isDetailDisplay = false">返回</el-button>
        </p>
        <el-button type="primary" size="small" @click="add">新增</el-button>
        <el-button type="primary" size="small" v-right-code="'Ruletoorderconnect:Createoredit'" @click="save">保存</el-button>
      </div>
    </div>
    <el-tabs type="border-card" v-model="activeName" @tab-click="tab1Click">
      <el-tab-pane name="tabbasic" label="基本信息">
        <div class="form-list">
          <el-form :model="dataSource" ref="_ruleForm" :rules="projectCheckRule">
            <el-form-item>
              <el-col :span="4" class="form-title"><span style="color: red">*</span>规则名称：</el-col>
              <el-col :span="8">
                <el-form-item prop="RuleName">
                  <el-input type="text" v-model="dataSource.RuleName" maxlength="50"></el-input>
                </el-form-item>
              </el-col>
            </el-form-item>
            <el-form-item>
              <el-col :span="4" class="form-title"><span style="color: red">*</span>衔接类型：</el-col>
              <el-col :span="8">
                <el-form-item prop="RuleConnectType">
                  <el-select v-model="dataSource.RuleConnectType" filterable placeholder="请选择">
                    <el-option v-for="item in connectTypeList" :key="item.Value" :label="item.Label" :value="item.Value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="4" class="form-title">
                <span style="color: red">*</span>货主名称：</el-col>
              <el-col :span="8">
                  <ChooseCustomer v-model="dataSource.CustomerCode" :customerName.sync="dataSource.CustomerName" :onChange="customerChange"></ChooseCustomer>
              </el-col>
            </el-form-item>
            <el-form-item>
              <el-col :span="4" class="form-title">
                <span style="color: red">*</span>仓配联动方式：</el-col>
              <el-col :span="8">
                <el-form-item prop="WarehouseLinkType">
                  <el-select v-model="dataSource.WarehouseLinkType" filterable placeholder="请选择">
                    <el-option v-for="item in WarehouseLinkTypeList" :key="item.Value" :label="item.Label"
                      :value="item.Value" >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              
              <el-col :span="4" class="form-title">
                <span style="color: red">*</span>物流订单类型：</el-col>
              <el-col :span="8">
                <el-form-item prop="BusinessType">
                  <el-select v-model="dataSource.BusinessType" filterable placeholder="请选择">
                    <el-option v-for="item in logisticsOrderTypeCodeList" :key="item.Value" :label="item.Label"
                      :value="item.Value" @change="updateBillType()">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-form-item>
            <el-form-item>
              <el-col :span="4" class="form-title"><span style="color: red">*</span>生效日期：</el-col>
              <el-col :span="8">
                <el-form-item prop="EffectiveDate">
                  <el-date-picker v-model.trim="dataSource.EffectiveDate" placeholder="选择日期" type="date" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="4" class="form-title"><span style="color: red">*</span>失效日期：</el-col>
              <el-col :span="8">
                <el-form-item prop="ExpiredDate">
                  <el-date-picker v-model.trim="dataSource.ExpiredDate" placeholder="选择日期" type="date" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
                </el-form-item>
              </el-col>
            </el-form-item>
            
             <el-form-item>
               <el-col :span="4" class="form-title"><span style="color: red">*</span><span>状态：</span></el-col>
              <el-col :span="8">
                <el-form-item>
                  <el-radio v-model="dataSource.EnableStatus" label="100">启用</el-radio>
                  <el-radio v-model="dataSource.EnableStatus" label="200">停用</el-radio>
                </el-form-item>
              </el-col>
                            <el-col :span="4" class="form-title"><span style="color: red">*</span>优先级：</el-col>
                            <el-col :span="8">
                                <el-form-item prop="Priority">
                                     <el-input type="text" maxlength="5" v-model.number="dataSource.Priority"></el-input>
                                </el-form-item>
                            </el-col>
                            
                        </el-form-item>
                        <el-form-item>
              <el-col :span="4" class="form-title">规则描述：</el-col>
              <el-col :span="20">
                <el-form-item prop="Remark">
                  <el-input type="textarea" :rows="2" maxlength="200" placeholder="请输入内容" v-model="dataSource.Remark">
                  </el-input>
                </el-form-item>
              </el-col>
            </el-form-item>
          </el-form>
        </div>
      </el-tab-pane>
    </el-tabs>

    <el-tabs type="border-card" v-model="activeNameConfig">
      <el-tab-pane label="命中规则" name="first">
        <el-row>
          <el-radio-group v-model="dataSource.ParamOpeatorType" size="mini">
            <el-radio-button :label="1">且</el-radio-button>
            <el-radio-button :label="2">或</el-radio-button>
          </el-radio-group>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-table :data="dataSource.Detail">
              <el-table-column prop="sort" label="序号" width="55">
              </el-table-column>
              <el-table-column prop="ParamFieldLabel" label="参数名称" align="center">
                <template slot-scope="scope">
                  <el-select v-model="scope.row.ParamFieldName" filterable placeholder="请选择" v-if="scope.row.isEdit"
                    @change="updateParamField(scope.row)">
                    <el-option v-for="item in dataSource.ruledtlofmetadatalist" :key="item.ParamFieldName"
                      :label="item.ParamFieldLabel" :value="item.ParamFieldName">
                    </el-option>
                  </el-select>
                  <span v-else v-text="scope.row.ParamFieldLabel"></span>
                </template>
              </el-table-column>

              <el-table-column prop="OperatorTag" label="操作符" align="center">
                <template slot-scope="scope">
                  <el-select v-if="scope.row.isEdit" v-model="scope.row.ParamOperator" filterable placeholder="请选择"
                    @change="updateOperator(scope.row)">
                    <el-option v-for="item in scope.row.OperatorListSelectAll" :key="item.Value" :label="item.Label"
                      :value="item.Value">
                    </el-option>
                  </el-select>

                  <span v-else v-text="scope.row.ParamOperatorTag"></span>
                </template>
              </el-table-column>

              <el-table-column prop="ParamValue" width="300" label="参数值" align="center">
                <template slot-scope="scope">
                  <el-date-picker v-if="scope.row.isEdit &&
                    scope.row.ruleDtlOfMetaData != null &&
                    scope.row.ruleDtlOfMetaData.ParamFieldControl ==
                    ParamFieldControlCode.Date &&
                    scope.row.ruleDtlOfMetaData.ParamFieldValueType ==
                    ParamFieldValueTypeCode.DateTimeFormat
                    " v-model="scope.row.ParamValue" type="datetime" placeholder="选择时间" format="yyyy-MM-dd HH:mm:ss"
                    value-format="yyyy-MM-dd HH:mm:ss" @change="changeParamValue(scope.row)"></el-date-picker>

                  <el-date-picker v-else-if="scope.row.isEdit &&
                    scope.row.ruleDtlOfMetaData != null &&
                    scope.row.ruleDtlOfMetaData.ParamFieldControl ==
                    ParamFieldControlCode.Date &&
                    scope.row.ruleDtlOfMetaData.ParamFieldValueType ==
                    ParamFieldValueTypeCode.DateFormat
                    " v-model="scope.row.ParamValue" type="date" placeholder="选择日期"
                    @change="changeParamValue(scope.row)"></el-date-picker>

                  <span v-else-if="scope.row.isEdit &&
                    scope.row.ruleDtlOfMetaData != null &&
                    scope.row.ruleDtlOfMetaData.ParamFieldType ==
                    ParamFieldTypeCode.BooleanType &&
                    scope.row.ruleDtlOfMetaData.ParamFieldControl ==
                    ParamFieldControlCode.SingleChoice
                    ">
                    <el-radio-group v-model="scope.row.ParamValue">
                      <el-radio label="true" @change="changeParamValue(scope.row)">是</el-radio>
                      <el-radio label="false" @change="changeParamValue(scope.row)">否</el-radio>
                    </el-radio-group>
                  </span>

                  <el-select v-else-if="scope.row.isEdit &&
                    scope.row.ruleDtlOfMetaData != null &&
                    scope.row.ruleDtlOfMetaData.ParamFieldType ==
                    ParamFieldTypeCode.IntType &&
                    scope.row.ruleDtlOfMetaData.ParamFieldControl ==
                    ParamFieldControlCode.Select
                    " v-model="scope.row.ParamValue">
                    <el-option v-for="item in scope.row.ruleDtlOfMetaData.DictionaryList" :key="item.Value"
                      :label="item.Label" :value="item.Value">
                    </el-option>
                  </el-select>

                  <el-input v-else-if="scope.row.isEdit" v-model="scope.row.ParamValue"
                    @change="changeParamValue(scope.row)"></el-input>

                  <span v-if="!scope.row.isEdit" v-text="scope.row.ParamValueTag"></span>
                </template>
              </el-table-column>

              <el-table-column label="操作" :min-width="80">
                <template slot-scope="scope">
                  <el-button @click="editData(scope.row)" size="small" type="text" v-if="!scope.row.isEdit">编辑</el-button>
                  <el-button @click="endEditData(scope.row)" size="small" type="text"
                    v-if="scope.row.isEdit">完成</el-button>
                  <el-button @click="deleteData(scope.row)" size="small" type="text">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
        <div class="h-handle-button" center>
          <center>
            <el-button type="text" size="small" @click="addruledetail()">新增</el-button>
          </center>
        </div>
      </el-tab-pane>
    </el-tabs>

      <el-tabs type="border-card" v-model="activeNameConfig3" v-show="dataSource.RuleConnectType==100">
      <el-tab-pane label="补充规则" name="third">
        <el-row>
          <div class="form-list">
            <el-form :model="dataSource" ref="_ruleForm">
              <el-col :span="3" class="form-title">如是运输分段拆分则满足：</el-col>
              <el-col :span="5">
                <el-form-item prop="SplitSegmentsType">
                  <el-select v-model="dataSource.SplitSegmentsType" filterable placeholder="请选择" @change="addruleotherdetail()">
                    <el-option v-for="item in orderSplitTypeCodeList" :key="item.Value" :label="item.Label" :value="item.Value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="2" class="form-title">时命中</el-col>
            </el-form>
          </div>
        </el-row>
        <el-row style="display:none">
          <el-col :span="24" v-if="dataSource.SplitSegmentsType!=null" >
            <el-table :data="dataSource.FixedDetail">
              <el-table-column prop="sort" label="序号" width="55">
              </el-table-column>
              <el-table-column prop="ParamFieldLabel" label="参数名称" align="center">
                <template slot-scope="scope">
                  <el-select v-model="scope.row.ParamFieldName" filterable placeholder="请选择" v-if="scope.row.isEdit"
                    @change="updateParamField(scope.row)">
                    <el-option v-for="item in FixedDetailSelect" :key="item.ParamFieldName"
                      :label="item.ParamFieldLabel" :value="item.ParamFieldName">
                    </el-option>
                  </el-select>

                  <span v-else v-text="scope.row.ParamFieldLabel"></span>
                </template>
              </el-table-column>

              <el-table-column prop="OperatorTag" label="操作符" align="center">
                <template slot-scope="scope">
                  <el-select v-if="scope.row.isEdit" v-model="scope.row.ParamOperator" filterable placeholder="请选择"
                    @change="updateOperator(scope.row)">
                    <el-option v-for="item in scope.row.OperatorListSelectAll" :key="item.Value" :label="item.Label"
                      :value="item.Value">
                    </el-option>
                  </el-select>

                  <span v-else v-text="scope.row.ParamOperatorTag"></span>
                </template>
              </el-table-column>

              <el-table-column prop="ParamValue" width="300" label="参数值" align="center">
                <template slot-scope="scope">
                  <el-date-picker v-if="scope.row.isEdit &&
                    scope.row.ruleDtlOfMetaData != null &&
                    scope.row.ruleDtlOfMetaData.ParamFieldControl ==
                    ParamFieldControlCode.Date &&
                    scope.row.ruleDtlOfMetaData.ParamFieldValueType ==
                    ParamFieldValueTypeCode.DateTimeFormat
                    " v-model="scope.row.ParamValue" type="datetime" placeholder="选择时间" format="yyyy-MM-dd HH:mm:ss"
                    value-format="yyyy-MM-dd HH:mm:ss" @change="changeParamValue(scope.row)"></el-date-picker>

                  <el-date-picker v-else-if="scope.row.isEdit &&
                    scope.row.ruleDtlOfMetaData != null &&
                    scope.row.ruleDtlOfMetaData.ParamFieldControl ==
                    ParamFieldControlCode.Date &&
                    scope.row.ruleDtlOfMetaData.ParamFieldValueType ==
                    ParamFieldValueTypeCode.DateFormat
                    " v-model="scope.row.ParamValue" type="date" placeholder="选择日期"
                    @change="changeParamValue(scope.row)"></el-date-picker>

                  <span v-else-if="scope.row.isEdit &&
                    scope.row.ruleDtlOfMetaData != null &&
                    scope.row.ruleDtlOfMetaData.ParamFieldType ==
                    ParamFieldTypeCode.BooleanType &&
                    scope.row.ruleDtlOfMetaData.ParamFieldControl ==
                    ParamFieldControlCode.SingleChoice
                    ">
                    <el-radio-group v-model="scope.row.ParamValue">
                      <el-radio label="true" @change="changeParamValue(scope.row)">是</el-radio>
                      <el-radio label="false" @change="changeParamValue(scope.row)">否</el-radio>
                    </el-radio-group>
                  </span>

                  <el-select v-else-if="scope.row.isEdit &&
                    scope.row.ruleDtlOfMetaData != null &&
                    scope.row.ruleDtlOfMetaData.ParamFieldType ==
                    ParamFieldTypeCode.IntType &&
                    scope.row.ruleDtlOfMetaData.ParamFieldControl ==
                    ParamFieldControlCode.Select
                    " v-model="scope.row.ParamValue">
                    <el-option v-for="item in scope.row.ruleDtlOfMetaData.DictionaryList" :key="item.Value"
                      :label="item.Label" :value="item.Value">
                    </el-option>
                  </el-select>

                  <el-input v-else-if="scope.row.isEdit" v-model="scope.row.ParamValue"
                    @change="changeParamValue(scope.row)"></el-input>

                  <span v-if="!scope.row.isEdit" v-text="scope.row.ParamValueTag"></span>
                </template>
              </el-table-column>

              <el-table-column label="操作" :min-width="80">
                <template slot-scope="scope">
                  <el-button @click="editOtherDetailData(scope.row)" size="small" type="text"
                    v-if="!scope.row.isEdit">编辑</el-button>
                  <el-button @click="endEditOtherDetailData(scope.row)" size="small" type="text"
                    v-if="scope.row.isEdit">完成</el-button>
                  <!-- <el-button @click="deleteOtherDetailData(scope.row)" size="small" type="text">删除</el-button> -->
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
        <el-row style="display:none;">
          <div class="form-list">
            <br>
            <el-form :model="dataSource" ref="_ruleForm">
              <el-col :span="6" class="form-title" style="font-size:18px;">作业前是否生成同等数量的衔接单据：</el-col>
              <el-col :span="6">
                <el-form-item prop="BuildBeforeTheJob">
                  <el-select v-model="dataSource.BuildBeforeTheJob" filterable placeholder="请选择">
                    <el-option v-for="item in whetherToGenerateDocumentsCodeList" :key="item.Value" :label="item.Label" :value="item.Value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-form>
          </div>
        </el-row>
        <el-row style="display:none;">
          <div class="form-list">
            <el-form :model="dataSource" ref="_ruleForm">
              <el-col :span="6" class="form-title" style="font-size:18px;">作业后是否生成同等数量的衔接单据：</el-col>
              <el-col :span="6">
                <el-form-item prop="BuildAfterTheJob">
                  <el-select v-model="dataSource.BuildAfterTheJob" filterable placeholder="请选择">
                    <el-option v-for="item in whetherToGenerateDocumentsCodeList" :key="item.Value" :label="item.Label" :value="item.Value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-form>
          </div>
        </el-row>
      </el-tab-pane>
    </el-tabs>

    <el-tabs type="border-card" v-model="activeNameConfig2">
      <el-tab-pane label="执行规则" name="second">
         
        <el-row>
          <el-col :span="24">
            生成关联内部物流订单对应物流指令。<br><br>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="24" v-show="dataSource.RuleConnectType==100">
            <div >以出库配送订单类型为例：</div>
            <div style="margin-top:10px;">当选择【先仓后配】时，在【出库单】到达以上配置的状态时，系统会将【运输单】自动生成物流指令。</div>
            <div style="margin-top:10px;">当选择【先配后仓】时，在【运输单】到达以上配置的状态时，系统会将【出库单】自动生成物流指令。</div>
          </el-col>
          <el-col :span="24" v-show="dataSource.RuleConnectType==200">
            <div >以调拨（含运输）订单类型为例：</div>
            <div style="margin-top:10px;">当配置该规则后，调拨入库物流指令下发的顺序将以出库的某个作业状态驱动生成。</div>
          </el-col>
        </el-row>
         
      </el-tab-pane>
    </el-tabs>

  

  </div>
</template>
<script>
export default {
  data() {
    var _this = this;
    var checkRuleName = function (rule, value, callback) {
      if (!value) return callback(new Error("请输入规则名称"));
      if(_this.Utils.isEmpty(value)) return callback(new Error("请输入规则名称"));
      callback();
    };
   
    var checkEffectiveDate = function (rule, value, callback) {
      if (value == null || value.length == 0)
        return callback(new Error("生效日期不能为空"));
      callback();
    };

    var checkExpiredDate = function (rule, value, callback) {
      if (value == null || value.length == 0)
        return callback(new Error("失效日期不能为空"));
      callback();
    };

    var checkPriority= function (rule, value, callback) {
      if (value == null || value.length == 0)
          return callback(new Error("优先级不能为空"));
      if(value>32767)
          return callback(new Error("优先级数值过大"));
      callback();
    };

    var checkWarehouseLinkType= function (rule, value, callback) {
      if (value == null || value.length == 0)
          return callback(new Error("仓配联动方式不能为空"));
      callback();
    };

    var checkRuleConnectType= function (rule, value, callback) {
      if (value == null || value.length == 0)
          return callback(new Error("衔接类型不能为空"));
      callback();
    };
        
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
      activeName: "tabbasic",
      activeNameConfig: "first",
      activeNameConfig2: "second",
      activeNameConfig3: "third",
      multipleSelection: [],
      FixedDetailSelect: [],
      isDisabled: false,
      projectCheckRule: {
        RuleName: [{ validator: checkRuleName, trigger: "blur" }],
        EffectiveDate: [{ validator: checkEffectiveDate, trigger: "blur" }],
        ExpiredDate: [{ validator: checkExpiredDate, trigger: "blur" }],
        Priority:[{ validator: checkPriority, trigger: "blur" }],
        WarehouseLinkType:[{ validator: checkWarehouseLinkType, trigger: "blur" }],
        RuleConnectType:[{ validator: checkRuleConnectType, trigger: "blur" }],
      },
      addruledetailOptions: {
        visible: false,
        size: "small",
        width: "40%",
      },
      currentEnterpriseSelectedRow: {},
      //选择仓库
      warehouseConfig: {
        isDetailDisplay: true,
      },
      warehouseOptions: {
        visible: false,
        size: "small",
      },
      currentWarehouseSelectedRow: {},
      queryParam: {
        PageIndex: 1,
        PageSize: 10,
      },
      configdataSource: {
        ColDefs: {},
        Result: [],
        TotalCount: 0,
      },
      inputVisible: false,
      inputValue: "",
      whetherToGenerateDocumentsCodeList: [],
      orderSplitTypeCodeList: [],
      logisticsOrderTypeCodeList: [],
      projectList: [],
      gainTypeList: [],
      billTypeList: [],
      billBodyList: [],
      paramFieldTypeList: [],
      paramFieldControlList: [],
      operatorList: [],
      paramFieldValueTypeList: [],
      connectTypeList: [],
      ParamFieldControlCode: {
        POPUP: 100,
        Select: 101,
        InputText: 102,
        MultilineInput: 103,
        Date: 104,
        SingleChoice: 105,
        CheckChoice: 106,
      },
      ParamFieldValueTypeCode: {
        SQL: 100,
        Dictionary: 101,
        Enumeration: 102,
        Text: 103,
        DateFormat: 104,
        DateTimeFormat: 105,
        FixedOptions: 106,
      },
      ParamFieldTypeCode: {
        IntType: 1,
        VarcharType: 2,
        BooleanType: 3,
        DateType: 4,
        DateTimeType: 5,
        NumericType: 6,
      },
      WarehouseLinkTypeList:[],
    };
  },
  props: {
    config: {
      isDetailDisplay: false,
    },
    dataSource: {},
  },
  computed: {},
  watch: {
    dataSource: {
      handler(curVal, oldVal) {
        this.isDisabled =
          this.Utils.emptyGuid != this.dataSource.WarehouseProjectID;
      },
      deep: true,
    },
  },

  mounted() {
    this.Event.$on("clearEditFormofOrderAudit", () => this.resetForm);
    this.initProjectList();
    this.initBillTypeList();
    this.initBillBodyList();
    this.initParamFieldTypeList();
    this.initParamFieldControlList();
    this.initOperatorList();
    this.initParamFieldValueTypeList();
    this.initDetail();
    this.updateBillType();
    this.initLogisticsOrderTypeCodeList();
    this.initOrderSplitTypeCodeList();
    this.initWhetherToGenerateDocumentsCodeList();
    this.initWarehouseLinkTypeList();
    this.initConnectTypeList();
  },

  methods: {
    //选择客户
    onEnterpriseSelectedRow: function (row) {
      this.currentEnterpriseSelectedRow = row;
    },
    initWarehouseLinkTypeList:function()
    {
      var _this = this;
      _this.$ajax.send(
        "omsapi/ruleToOrderConnect/getWarehouseLinkTypeList",
        "get",
        {},
        (data) => {
          if (data.IsSuccess) {
            _this.WarehouseLinkTypeList = data.Result;
          }
        }
      );
    }
    ,
    initWhetherToGenerateDocumentsCodeList: function(){
      var _this = this;
      _this.$ajax.send(
        "omsapi/ruleToOrderConnect/getWhetherToGenerateDocumentsCodeList",
        "get",
        {},
        (data) => {
          if (data.IsSuccess) {
            _this.whetherToGenerateDocumentsCodeList = data.Result;
          }
        }
      );
    },
    initOrderSplitTypeCodeList: function (){
      var _this = this;
      _this.$ajax.send(
        "omsapi/ruleToOrderConnect/getOrderSplitTypeCodeList",
        "get",
        {},
        (data) => {
          if (data.IsSuccess) {
            _this.orderSplitTypeCodeList = data.Result;
          }
        }
      );
    },
    initLogisticsOrderTypeCodeList: function () {
      var _this = this;
      _this.$ajax.send(
        "omsapi/ruleToOrderConnect/getLogisticsOrderTypeCodeList",
        "get",
        {},
        (data) => {
          if (data.IsSuccess) {
            _this.logisticsOrderTypeCodeList = data.Result;
          }
        }
      );
    },
    initProjectList: function () {
      var _this = this;
          return;
      _this.$ajax.send(
        "omsapi/ruletoorderdisassembly/getprojectlist",
        "get",
        {},
        (data) => {
          if (data.IsSuccess) {
            _this.projectList = data.Result;
          }
        }
      );
    },
    initGainTypeList: function () {
      var _this = this;
      _this.$ajax.send(
        "omsapi/ruleofmetadata/getgaintypelist",
        "get",
        {},
        (data) => {
          if (data.IsSuccess) {
            _this.gainTypeList = data.Result;
          }
        }
      );
    },
    initBillTypeList: function () {
      var _this = this;
      _this.$ajax.send(
        "omsapi/ruleofmetadata/getbilltypelist",
        "get",
        {},
        (data) => {
          if (data.IsSuccess) {
            _this.billTypeList = data.Result;
          }
        }
      );
    },
    initBillBodyList: function () {
      var _this = this;
      _this.$ajax.send(
        "omsapi/ruleofmetadata/getbillbodylist",
        "get",
        {},
        (data) => {
          if (data.IsSuccess) {
            _this.billBodyList = data.Result;
          }
        }
      );
    },
    initDetail: function () {
      var _this = this;

      if (
        _this.dataSource.Detail != null &&
        _this.dataSource.Detail.length > 0
      ) {
        var index = 0;
        for (var i = 0; i < _this.dataSource.Detail.length; i++) {
          index++;
          _this.dataSource.Detail[i].sort = index;
          _this.dataSource.Detail[i].isEdit = false;
        }
      }
    },
    initParamFieldTypeList: function () {
      var _this = this;
      _this.$ajax.send(
        "omsapi/ruleofmetadata/getparamfieldtypelist",
        "get",
        {},
        (data) => {
          if (data.IsSuccess) {
            _this.paramFieldTypeList = data.Result;
          }
        }
      );
    },
    initParamFieldControlList: function () {
      var _this = this;
      _this.$ajax.send(
        "omsapi/ruleofmetadata/getparamfieldcontrollist",
        "get",
        {},
        (data) => {
          if (data.IsSuccess) {
            _this.paramFieldControlList = data.Result;
          }
        }
      );
    },
    initOperatorList: function () {
      var _this = this;
      _this.$ajax.send(
        "omsapi/ruleofmetadata/getoperatorlist",
        "get",
        {},
        (data) => {
          if (data.IsSuccess) {
            _this.operatorList = data.Result;
          }
        }
      );
    },
    initConnectTypeList: function () {
      var _this = this;
      _this.$ajax.send(
        "omsapi/ruleofmetadata/getconnecttypelist",
        "get",
        {},
        (data) => {
          if (data.IsSuccess) {
            _this.connectTypeList = data.Result;
          }
        }
      );
    },
    changeParamValue: function (obj) {
      var ParamFieldControl = "";
      if (obj.ruleDtlOfMetaData != null && obj.ruleDtlOfMetaData != undefined) {
        ParamFieldControl = obj.ruleDtlOfMetaData.ParamFieldControl;
      } else {
        ParamFieldControl = obj.ParamFieldControl;
      }

      var ParamFieldType = "";

      if (obj.ruleDtlOfMetaData != null && obj.ruleDtlOfMetaData != undefined) {
        ParamFieldType = obj.ruleDtlOfMetaData.ParamFieldType;
      } else {
        ParamFieldType = obj.ParamFieldType;
      }

      if (ParamFieldControl == this.ParamFieldControlCode.InputText) {
        obj.ParamValueTag = obj.ParamValue;
      } else if (ParamFieldControl == this.ParamFieldControlCode.SingleChoice && ParamFieldType == this.ParamFieldTypeCode.BooleanType) {
        if (obj.ParamValue == "true") {
          obj.ParamValueTag = "是";
        } else {
          obj.ParamValueTag = "否";
        }
      } else if (obj.ruleDtlOfMetaData.ParamFieldControl ==
        this.ParamFieldControlCode.Date &&
        obj.ruleDtlOfMetaData.ParamFieldValueType ==
        this.ParamFieldValueTypeCode.DateTimeFormat) {
        obj.ParamValueTag = obj.ParamValue;
      } else if (obj.ruleDtlOfMetaData.ParamFieldControl ==
        this.ParamFieldControlCode.Date &&
        obj.ruleDtlOfMetaData.ParamFieldValueType ==
        this.ParamFieldValueTypeCode.DateFormat) {
        obj.ParamValueTag = this.Utils.formatDate(obj.ParamValue);
      }
    },
    initParamFieldValueTypeList: function () {
      var _this = this;
      _this.$ajax.send(
        "omsapi/ruleofmetadata/getparamfieldvaluetypelist",
        "get",
        {},
        (data) => {
          if (data.IsSuccess) {
            _this.paramFieldValueTypeList = data.Result;
          }
        }
      );
    },
    updateParamFieldType: function (obj) {
      var list = this.paramFieldTypeList;
      for (var i = 0; i < list.length; i++) {
        if (obj.ParamFieldType == list[i].Value) {
          obj.ParamFieldTypeTag = list[i].Label;
          break;
        }
      }
    },
    updateParamFieldControl: function (obj) {
      var list = this.paramFieldControlList;
      for (var i = 0; i < list.length; i++) {
        if (obj.ParamFieldControl == list[i].Value) {
          obj.ParamFieldControlTag = list[i].Label;
          break;
        }
      }
    },
    updateParamField: function (obj) {
      var list = this.dataSource.ruledtlofmetadatalist;

      for (var i = 0; i < list.length; i++) {
        if (obj.ParamFieldName == list[i].ParamFieldName) {
          obj.ParamFieldLabel = list[i].ParamFieldLabel;
          obj.OperatorListSelectAll = list[i].OperatorListSelectAll;
          obj.ruleDtlOfMetaData = list[i];
          obj.TableName=list[i].TableName;
          obj.RuleDtlId = list[i].RuleDtlId;
          break;
        }
      }

      if (
        obj.isEdit &&
        obj.ruleDtlOfMetaData != null &&
        obj.ruleDtlOfMetaData.ParamFieldType ==
        this.ParamFieldTypeCode.BooleanType &&
        obj.ruleDtlOfMetaData.ParamFieldControl ==
        this.ParamFieldControlCode.SingleChoice
      ) {
        if (obj.ParamValue == null || obj.ParamValue.length == 0) {
          var temp = this.Utils.cloneObj(obj);
          temp.ParamValue = "true";
          temp.ParamValueTag = "是";
          obj = temp;
        } else if (obj == "true") {
          obj.ParamValueTag = "是";
        } else {
          obj.ParamValueTag = "否";
        }
      }
    },
    updateBillType: function () {
      var _this = this;
      var list = this.billTypeList;
      var BillType = this.dataSource.BusinessType;

      if (
        this.dataSource.ProjectCode == null ||
        this.dataSource.ProjectCode.length == 0
      ) {
        return;
      }

      if (BillType == null || BillType.length == 0) {
        return;
      }

      _this.$ajax.send(
        "omsapi/ruleofmetadata/getruledtlofmetadatalist",
        "post",
        { projectcode: this.dataSource.ProjectCode, billType: 3 },
        (data) => {
          if (data.IsSuccess) {
            _this.dataSource.ruledtlofmetadatalist = data.Result;

            var FixedDetail = [];

            var index = 0;
            if (data.Result != null && data.Result.length > 0) {
              for (var a = 0; a < data.Result.length; a++) {
                if (data.Result[a].DisplayType == 2) {
                  index++;
                  var obj = _this.Utils.cloneObj(data.Result[a]);
                  obj.sort = index;
                  obj.isEdit = false;
                  if (obj.OperatorListSelectAll != null && obj.OperatorListSelectAll.length > 0) {
                    obj.ParamOperator = obj.OperatorListSelectAll[0].Value;
                    obj.ParamOperatorTag = obj.OperatorListSelectAll[0].Label;
                  }
                  FixedDetail.push(obj);
                }
              }
            }
            _this.FixedDetailSelect = FixedDetail;
            _this.dataSource.FixedDetail = [];

            if (_this.FixedDetailSelect != null && _this.FixedDetailSelect.length > 0) {
              _this.dataSource.FixedDetail.push(_this.FixedDetailSelect[0]);
            }
          }
        }
      );
    },
    updateOperator: function (obj) {
      var list = obj.OperatorListSelectAll;
      var strOperatorTag = "";
      var strOperator = "";

      if (
        obj.OperatorListSelectAll == null ||
        obj.OperatorListSelectAll.length == 0
      ) {
        obj.OperatorTag = "";
        return;
      }

      for (var i = 0; i < list.length; i++) {
        if (obj.ParamOperator == list[i].Value) {
          strOperatorTag += list[i].Label + ";";
          strOperator += list[i].Value + ";";
          break;
        }
      }
      obj.ParamOperatorTag = strOperatorTag;
    },
    updateParamFieldValueType: function (obj) {
      var list = this.paramFieldValueTypeList;
      for (var i = 0; i < list.length; i++) {
        if (obj.ParamFieldValueType == list[i].Value) {
          obj.ParamFieldValueTypeTag = list[i].Label;
          break;
        }
      }
    },
    updateParamFieldPage: function (obj) {
      var list = this.boolList;
      for (var i = 0; i < list.length; i++) {
        if (obj.ParamFieldPage == list[i].Value) {
          obj.ParamFieldPageTag = list[i].Label;
          break;
        }
      }
    },
    updateParamFieldStatus: function (obj) {
      var list = this.boolList;
      for (var i = 0; i < list.length; i++) {
        if (obj.ParamFieldStatus == list[i].Value) {
          obj.ParamFieldStatusTag = list[i].Label;
          break;
        }
      }
    },
    add: function () {
      this.Event.$emit("onAddRuleOfOrderConnect");
    },
    save: function () {
      var _this = this;
      _this.$refs["_ruleForm"].validate((valid) => {
        _this.isPassValidate = valid;
        if (valid) {
           if(_this.dataSource.CustomerCode==null || _this.dataSource.CustomerCode.length==0)
                        {
                            _this.Utils.messageBox("未选择货主名称", "error");
                            return ;
                        }
          if (
            _this.dataSource.Detail != null &&
            _this.dataSource.Detail.length > 0
          ) {
            //配置对象验证
            var detail = _this.dataSource.Detail;
            for (var i = 0; i < detail.length; i++) {
              if (
                detail[i].ParamFieldName == null ||
                detail[i].ParamFieldName.length == 0
              ) {
                _this.Utils.messageBox("参数名称不能为空", "error");
                return;
              }

              if (
                detail[i].ParamOperator == null ||
                detail[i].ParamOperator.length == 0
              ) {
                _this.Utils.messageBox("操作符不能为空", "error");
                return;
              }
            }
          }else
          {
             _this.Utils.messageBox("命中规则必填", "error");
             return;
          }

          delete _this.dataSource.DisplayRuleConnectType
          delete _this.dataSource.DisplayEnableStatus
          delete  _this.dataSource.DisplayWarehouseLinkType

          _this.$ajax.send(
            "omsapi/ruleToOrderConnect/createOrEdit",
            "post",
            _this.dataSource,
            (data) => {
              if (data.IsSuccess) {
                // _this.Utils.messageBox(data.OperationDesc, "success");
                _this.$parent.syncDataSource();
                _this.Event.$emit("reloadRuleOfOrderConnectList");
                _this.dataSource.Detail = [];
                _this.$refs["_ruleForm"].resetFields(); //清空表单
                _this.Utils.messageBox("保存成功.", "success");
                _this.config.isDetailDisplay = false;
              } else {
                _this.Utils.messageBox(data.OperationDesc, "error");
              }
            }
          );
        } else {
          return false;
        }
      });
    },

    addruleotherdetail: function (){
      var _this = this;
      if(this.dataSource.OtherDetail != null){
        return;
      }
      var list = this.billTypeList;
      var BillType = this.dataSource.BusinessType;
      if (
        this.dataSource.ProjectCode == null ||
        this.dataSource.ProjectCode.length == 0
      ) {
        return;
      }

      if (BillType == null || BillType.length == 0) {
        return;
      }
      _this.$ajax.send(
        "omsapi/ruleofmetadata/getruledtlofmetadatalist",
        "post",
        { projectcode: this.dataSource.ProjectCode, billType: 3 },
        (data) => {
                if (data.IsSuccess) {
                  var newruledetial = data.Result;
                  if (
                    _this.dataSource.OtherDetail == undefined ||
                    _this.dataSource.OtherDetail == null
                  ) {
                    _this.dataSource.OtherDetail = [];
                  }


                  for (var i = 0; i < _this.dataSource.OtherDetail.length; i++) {
                    _this.dataSource.OtherDetail[i].isEdit = false;
                  }

                  var index = _this.dataSource.OtherDetail.length;
                  index++;
                  newruledetial.sort = index;
                  newruledetial.isEdit = true;
                  newruledetial.ruledtlofmetadatalist = _this.Utils.cloneObj(_this.dataSource.ruledtlofmetadatalist);
                  _this.dataSource.OtherDetail.push(newruledetial);
                }
              }
      );
    },

    addruleexecutedetail: function () {
      var _this = this;
      var list = this.billTypeList;
      var BillType = this.dataSource.BusinessType;

      if (
        this.dataSource.ProjectCode == null ||
        this.dataSource.ProjectCode.length == 0
      ) {
        return;
      }

      if (BillType == null || BillType.length == 0) {
        return;
      }

      _this.$ajax.send(
        "omsapi/ruleofmetadata/getruledtlofmetadatalist",
        "post",
        { projectcode: this.dataSource.ProjectCode, billType: 3 },
        (data1) => {
          if (data1.IsSuccess) {
            _this.dataSource.ruledtlofmetadatalist = data1.Result;
            
            if (_this.dataSource.ruledtlofmetadatalist == null || _this.dataSource.ruledtlofmetadatalist.length == 0) {
              _this.Utils.messageBox("对象配置参数为空", "error");
              return;
            }

            _this.$ajax.send(
              "omsapi/ruleofmetadata/getemptyruledetail",
              "get",
              {},
              (data) => {
                if (data.IsSuccess) {
                  var newruledetial = data.Result;
                  if (
                    _this.dataSource.ExecuteDetail == undefined ||
                    _this.dataSource.ExecuteDetail == null
                  ) {
                    _this.dataSource.ExecuteDetail = [];
                  }


                  for (var i = 0; i < _this.dataSource.ExecuteDetail.length; i++) {
                    _this.dataSource.ExecuteDetail[i].isEdit = false;
                  }

                  var index = _this.dataSource.ExecuteDetail.length;
                  index++;
                  newruledetial.sort = index;
                  newruledetial.isEdit = true;
                  newruledetial.ruledtlofmetadatalist = _this.Utils.cloneObj(_this.dataSource.ruledtlofmetadatalist);
                  _this.dataSource.ExecuteDetail.push(newruledetial);
                }
              }
            );
          }
        }
      );
    },
    addruledetail: function () {
      var _this = this;
      var list = this.billTypeList;
      var BillType = this.dataSource.BusinessType;

      if (BillType == null || BillType.length == 0) {
        return;
      }

      _this.$ajax.send(
        "omsapi/ruleofmetadata/getruledtlofmetadatalist",
        "post",
        { projectcode: this.dataSource.ProjectCode, billType: 1 },
        (data1) => {
          if (data1.IsSuccess) {
            _this.dataSource.ruledtlofmetadatalist = data1.Result;
            
            if (_this.dataSource.ruledtlofmetadatalist == null || _this.dataSource.ruledtlofmetadatalist.length == 0) {
              _this.Utils.messageBox("对象配置参数为空", "error");
              return;
            }

            _this.$ajax.send(
              "omsapi/ruleofmetadata/getemptyruledetail",
              "get",
              {},
              (data) => {
                if (data.IsSuccess) {
                  var newruledetial = data.Result;
                  if (
                    _this.dataSource.Detail == undefined ||
                    _this.dataSource.Detail == null
                  ) {
                    _this.dataSource.Detail = [];
                  }


                  for (var i = 0; i < _this.dataSource.Detail.length; i++) {
                    _this.dataSource.Detail[i].isEdit = false;
                  }

                  var index = _this.dataSource.Detail.length;
                  index++;
                  newruledetial.sort = index;
                  newruledetial.isEdit = true;
                  newruledetial.ruledtlofmetadatalist = _this.Utils.cloneObj(_this.dataSource.ruledtlofmetadatalist);
                  _this.dataSource.Detail.push(newruledetial);
                }
              }
            );
          }
        }
      );
    },
    handleClose(tag) {
      this.dataSource.dynamicTags.splice(
        this.dataSource.dynamicTags.indexOf(tag),
        1
      );
    },

    showInput() {
      this.inputVisible = true;
      this.$nextTick((_) => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },

    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue) {
        this.dataSource.dynamicTags.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = "";
    },
    // deleteOtherDetailData: function (obj) {
    //   this.dataSource.OtherDetail.splice(this.dataSource.OtherDetail.indexOf(obj), 1);
    // },
    deleteExecuteDetailData: function (obj) {
      this.dataSource.ExecuteDetail.splice(this.dataSource.ExecuteDetail.indexOf(obj), 1);
    },
    deleteData: function (obj) {
      this.dataSource.Detail.splice(this.dataSource.Detail.indexOf(obj), 1);
    },
    tab1Click: function (tab, event) { },
    tab2Click: function (tab, event) { },

    editOtherDetailData: function (obj) {
      for (var i = 0; i < this.dataSource.Detail.length; i++) {
        this.dataSource.Detail[i].isEdit = false;
      }

      for (var i = 0; i < this.dataSource.ExecuteDetail.length; i++) {
        this.dataSource.ExecuteDetail[i].isEdit = false;
      }

      for (var i = 0; i < this.dataSource.FixedDetail.length; i++) {
        this.dataSource.FixedDetail[i].isEdit = false;
      }
      obj.isEdit = true;
    },
    editExecuteDetailData: function (obj) {

      for (var i = 0; i < this.dataSource.Detail.length; i++) {
        this.dataSource.Detail[i].isEdit = false;
      }

      for (var i = 0; i < this.dataSource.ExecuteDetail.length; i++) {
        this.dataSource.ExecuteDetail[i].isEdit = false;
      }
      obj.isEdit = true;
    },
    editData: function (obj) {
      for (var i = 0; i < this.dataSource.Detail.length; i++) {
        this.dataSource.Detail[i].isEdit = false;
      }
      obj.isEdit = true;
    },

    endEditOtherDetailData: function (obj){
      for (var i = 0; i < this.dataSource.Detail.length; i++) {
        this.dataSource.Detail[i].isEdit = false;
      }

      for (var i = 0; i < this.dataSource.ExecuteDetail.length; i++) {
        this.dataSource.ExecuteDetail[i].isEdit = false;
      }

      for (var i = 0; i < this.dataSource.FixedDetail.length; i++) {
        this.dataSource.FixedDetail[i].isEdit = false;
      }
      this.changeParamValue(obj);
    },
    endEditExecuteDetailData: function (obj) {

      for (var i = 0; i < this.dataSource.Detail.length; i++) {
        this.dataSource.Detail[i].isEdit = false;
      }

      for (var i = 0; i < this.dataSource.ExecuteDetail.length; i++) {
        this.dataSource.ExecuteDetail[i].isEdit = false;
      }
      this.changeParamValue(obj);
    },
    endEditData: function (obj) {
      for (var i = 0; i < this.dataSource.Detail.length; i++) {
        this.dataSource.Detail[i].isEdit = false;
      }
      this.changeParamValue(obj);
    },
    resetForm() {
      !this.isPassValidate && this.$refs["_ruleForm"].resetFields(); //清空表单
      this.dataSource.Detail = [];
      this.dataSource.ExecuteDetail = [];
      this.dataSource.FixedDetail = [];
      this.dataSource.EnableStatus = "100";
      this.dataSource.TransportOrderStatus = false;
      this.dataSource.PhurchaseOrderStatus = false;
      this.dataSource.SaleOrderStatus = false;
    },
    reloadPageList: function () {
      this.initialize();
    },
    initialize() {
      this.onPageChange(this.queryParam);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      this.$emit("onCheckRow", this.multipleSelection);
    },
    onPageChange(param) {
      var _this = this;
      this.queryParam = param;
      this.$ajax.query(
        "omsapi/logisticsProject/findList",
        "post",
        this.queryParam,
        (data) => {
          _this.dataSource = data;
        }
      );
    },
    projectChange() {
      this.updateBillType();
    },
     customerChange(){
                //this.warehouseConfig.CustomerCode=this.dataSource.CustomerCode
                //this.productConfig.CustomerCode=this.dataSource.CustomerCode
            },
  },
  components: {
    //"Enterprise": resolve => { require(['../../../selector/enterpriseselector.vue'], resolve) },
    //"Warehouse": resolve => { require(['../../../selector/servicewarehouseselector.vue'], resolve) }
  },
};
</script>

<style>
.el-tag+.el-tag {
  margin-left: 10px;
}

.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
</style>